import { Input} from 'antd';
import React from 'react';
import './wash.css'; 
import {PlusCircleFilled,CameraFilled } from '@ant-design/icons';



const SafetyBox=()=>{
    return(
        <div className="washbox_s">
            <h3>Install Safety Seals & Record Below</h3>
             <p>Tag # ONLMO11 (Enter last 3)</p>
             <div className="service_details">
                <Input style={{width:'30%'}} placeholder="Add a Picture" suffix={<CameraFilled style={{fontSize:'18px'}} />}/>
             <Input style={{width:'30%'}} placeholder="Enter Input"/>
             <Input style={{width:'30%'}}/>
             <PlusCircleFilled style={{fontSize:'42px'}} />
             </div>
            </div>
    )
}

export default SafetyBox;