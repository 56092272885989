import React, { useState } from "react"
import Arrow from "../../../../assets/svg/down_arrow_icon.svg"
import { Input } from "antd"
import {
  HOPPER_CAR_CHANGE_OF_SERVICE,
  HOPPER_CAR_RINSE_OUT_RETURN_TO_SERVICE_INSPECTION,
  TANK_CAR_FINAL_INSPECTION,
  NO_CLEANING_REPAIR_ONLY_CAR_INSPECTION,
  REMARK_ONLY_INSPECTION,
} from "../../../../utils/helper"
import "./wash.css"
import Small from "./Small"
const FinalInspection = ({ hccs, hcro, onChange, tcfi, nocro, roi }) => {
  const { TextArea } = Input
  const [shows, setShows] = useState(false)
  const [longs, setLongs] = useState(100)
  const [openInput, setOpenInput] = useState([])
  const imageChange = (id, dataIndex) => {
    /** const isAlreadyOpen = openInput?.includes(id)
    if (isAlreadyOpen) {
      const filterdItem = openInput?.filter(item => item !== id)
      setOpenInput(filterdItem)
    } else {
      setOpenInput([...openInput, id])
    } */

    onChange && onChange(id, dataIndex)
  }
  const accordionHandlers = item => {
    setLongs(item.id)
    item.id === longs ? setShows(!shows) : setShows(true)
  }
  const data = [
    {
      id: 100,
      title: "Hopper Car Change of Service",
      description: (
        <Small
          name={HOPPER_CAR_CHANGE_OF_SERVICE}
          //openInput={openInput}
          details={hccs}
          text={true}
          imageChange={id => imageChange(id, 1)}
        />
      ),
    },
    {
      id: 101,
      title: "Hopper Car Rinse Out / Return To Service Inspection",
      description: (
        <Small
          name={HOPPER_CAR_RINSE_OUT_RETURN_TO_SERVICE_INSPECTION}
          openInput={openInput}
          details={hcro}
          text={false}
          imageChange={id => imageChange(id, 2)}
        />
      ),
    },
    {
      id: 102,
      title: "Tank Car Final Inspection",
      description: (
        <Small
          name={TANK_CAR_FINAL_INSPECTION}
          openInput={openInput}
          text={false}
          details={tcfi}
          imageChange={id => imageChange(id, 3)}
        />
      ),
    },
    {
      id: 103,
      title: "No Cleaning Repair Only Car Inspection",
      description: (
        <Small
          name={NO_CLEANING_REPAIR_ONLY_CAR_INSPECTION}
          openInput={openInput}
          text={false}
          details={nocro}
          imageChange={id => imageChange(id, 4)}
        />
      ),
    },
    {
      id: 104,
      title: "Remark Only Inspection",
      description: (
        <Small
          name={REMARK_ONLY_INSPECTION}
          openInput={openInput}
          text={false}
          details={roi}
          imageChange={id => imageChange(id, 5)}
        />
      ),
    },
  ]
  return (
    <>
      <div
        className="ticket_details_accordionyrd"
        style={{ marginTop: "-10px" }}
      >
        <div className="contact_sectionyrd">
          <div className="contact_containeryrd">
            {data.map((item, index) => {
              return (
                <>
                  <div
                    style={{
                      boxShadow: shows && item.id === longs ? "none" : "",
                      background: shows && item.id === longs ? "" : "#fff",
                    }}
                    key={index}
                    className={
                      shows && item.id === longs
                        ? "contact_accordion_activeyrd"
                        : "contact_accordionyrd"
                    }
                  >
                    <div
                      className="contact_accordion_headyrd"
                      onClick={() => accordionHandlers(item)}
                      style={{
                        background: "#fff",
                        padding: "0px 0px",
                        height: shows && item.id === longs ? "7vh" : "10vh",
                        borderBottom: "1px solid #00000029",
                      }}
                    >
                      <h3 style={{ color: "#274576" }}>{item.title}</h3>
                      <img
                        src={Arrow}
                        alt="icon forword"
                        style={{
                          transform:
                            shows && item.id === longs ? "rotate(180deg)" : "",
                        }}
                      />
                    </div>
                    <div
                      className={
                        shows && item.id === longs
                          ? "contact_accordion_desc_activeyrd"
                          : "contact_accordion_descyrd"
                      }
                    >
                      {item.description}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default FinalInspection
