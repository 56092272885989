import React, { useState, useEffect } from "react"
import moment from "moment"
import {
  IndustryType,
  TimeDifference,
  renderImage,
  FetchParamValue,
  RAIL_CAR,
} from "../../../../utils/helper"
import { DatePicker, Space } from "antd"
import ElpsImages from "../comp/ElpsImages"
import WashBox from "./WashBox"
import SafetyBox from "./SafetyBox"
const Washbox = props => {
  const { data, startTime } = props
  const [time, setTime] = useState(
    new Date(moment().format("MM/DD/yyyy HH:mm"))
  )
  const [timeStr, setTimeStr] = useState("Loading...")
  useEffect(() => {
    calculateTimeToString()
  }, [startTime])
  useEffect(() => {
    calculateTimeToString()
  }, [time])

  const calculateTimeToString = () => {
    setTimeStr(TimeDifference(startTime, time))
  }

  function onChange(value, dateString) {
    let endt = moment(dateString).format("MM/DD/yyyy HH:mm")
    let endTime = new Date(endt)
    props.setChangeWashTime(endTime)
    setTime(endTime)
  }
  return (
    <section className="equipment_oil_first_new_sewty">
      <div className="equipment_oil_first">
        <div className="equ_det_img">
          <img
            src={renderImage(
              data?.asset_details?.equipment_type_details?.param_description
            )}
            alt="card img"
          />
          <div>
            status:<span> {data?.ticket_status?.param_description}</span>
          </div>
        </div>
        <div className="equ_details">
          <div>
            ticket no.:<p>{data?.ticket_ref_id}</p>
          </div>
          <div>
            {IndustryType() == RAIL_CAR ? "car no.:" : "equipment no.:"}
            <p>{data?.asset_details?.equipment_no}</p>
          </div>
          <div>
            {IndustryType() == RAIL_CAR ? "car type:" : "equipment type:"}{" "}
            <p>
              {data?.asset_details?.equipment_type_details?.param_description}
            </p>
          </div>
          <div>
            customer name:<p>{data?.customer_details?.name}</p>
          </div>
          {IndustryType() == RAIL_CAR ? (
            ""
          ) : (
            <div>
              last contained:
              <p>
                {data?.asset_txns_details.last_known?.substr(
                  data.asset_txns_details.last_known.indexOf("-") + 1
                )}
              </p>
            </div>
          )}
          {IndustryType() == RAIL_CAR ? (
            ""
          ) : (
            <div>
              Services:{" "}
              <p style={{ width: "100%", display: "block" }}>
                {data?.services?.length !== 0
                  ? data.services?.map((item, i) => (
                      <span style={{ marginRight: 7 }}>
                        {FetchParamValue(item?.service_id)}{" "}
                        {data?.services?.length - i !== 1 && ","}
                      </span>
                    ))
                  : props?.services?.length !== 0
                  ? props?.services?.map((item, i) => (
                      <span style={{ marginRight: 7 }}>
                        {FetchParamValue(item?.service_id)}{" "}
                        {props?.services?.length - i !== 1 && ","}
                      </span>
                    ))
                  : "NA"}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="wash_taken_time">
        <div className="wash_time">
          <p style={{ marginBottom: "-5px" }}>time taken:</p>
          <p className="time_taken">{timeStr}</p>
          <div className="wash_time_edit_update">
            <Space direction="vertical" size={12}>
              <DatePicker
                size="large"
                className="wash_taken_date_time"
                showTime={{ defaultValue: moment(props?.time) }}
                onChange={onChange}
                allowClear={false}
                minTime={props.time}
                disabledDate={d =>
                  d.isBefore(
                    moment(parseInt(startTime))?.format("MM/DD/yyyy HH:mm")
                  )
                }
                format="MM/DD/yyyy hh:mm:ss A"
              />
            </Space>
          </div>
        </div>
        <div className="equipment_type">
          <h4>{data?.asset_txns_details?.remark}</h4>
        </div>
      </div>
      {IndustryType() === "Food_Grade" && (
        <>
          <ElpsImages radioHandle={e => props.radioHandle(e)} />
          <WashBox title="Alkaline Wash" />
          <WashBox title="Cold Rinse 2" />
          <WashBox title="Hot Rinse 3" />
          <SafetyBox />
        </>
      )}
    </section>
  )
}

export default Washbox
