import React, { useState, useEffect } from "react"
import Header from "../../../components/Yard/common/Header"
import { IndustryType, RAIL_CAR } from "../../../utils/helper"
import "./Wash_Details.css"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import Washbox from "./comp/Wash_box"
import AmountContainer from "./comp/AmountContainer"
import ServiceSelect from "./comp/ServiceSelect"
import TextArea from "../../../components/Yard/textArea/TextArea"
// import Button from "../../../components/Yard/Button/Button"
import DateTime from "../../../components/Yard/dateTime/DateTime"
import PictureCard from "../../../components/upload/PictureCard"
import { message, Modal, Button } from "antd"
import Certificate from "../../../pages/myYard/certificate/index"
import moment from "moment"
import API from "../../../api"
import { Routes } from "../../../utils/route"
import { Spin } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { getTicketDetails } from "../../../actions/myYardAction"
import { removeYardTicketAfterComplete } from "../../../actions/yardActions/YardActions"
import { browserName } from "react-device-detect"
import Head from "../../../components/Yard/header/Header"
import TicketSubmit from "../../../components/ticketSubmit/TicketSubmit"
import Arrow from "../../../assets/svg/down_arrow_icon.svg"
import FinalInspection from "./comp/FinalInspection"
import {
  HOPPER_CAR_CHANGE_OF_SERVICE,
  HOPPER_CAR_RINSE_OUT_RETURN_TO_SERVICE_INSPECTION,
  TANK_CAR_FINAL_INSPECTION,
  NO_CLEANING_REPAIR_ONLY_CAR_INSPECTION,
  REMARK_ONLY_INSPECTION,
} from "../../../utils/helper"
const Wash_Details = props => {
  const id = props?.id
  const [loading, setLoading] = useState(true)
  const {
    ticketCompleteYard,
    fetchServiceDetailsPost,
    updateCertificateNumber,
  } = API
  const [startTime, setStartTime] = useState(null)
  useEffect(() => {
    if (typeof window != "undefined") {
      setStartTime(localStorage.getItem("startTime"))
    }
  }, [])
  const [state, setState] = useState(null)
  const [text, setText] = useState("")
  const isChrome =
    browserName === "Chrome"
      ? "YYYY-MM-DD"
      : browserName === "Safari"
      ? "MM-DD-YYYY"
      : "YYYY-MM-DD"

  const [date, setDate] = useState(moment().format(isChrome))
  const [time, setTime] = useState(moment().format("HH:mm"))
  const [image, setImage] = useState([])
  const [gal, setGal] = useState(null)
  const [drum, setDrum] = useState(null)
  const [solvent, setSolvent] = useState(null)
  const [deodorizer, setDeodorizer] = useState(null)
  const [radioValue, setRadioValue] = useState(null)
  const [washTime, setWashTime] = useState(
    new Date(moment().format("MM/DD/yyyy HH:mm"))
  )
  const [serviceData, setServiceData] = useState([])
  const [ticketDetailsData, setTicketDetailsData] = useState(null)
  const [ticket, setTicket] = useState(null)
  const [ticketShow, setTicketShow] = useState(true)
  const [serviceGroupId, setServiceGroupId] = useState(null)
  const [nextTicket, setNextTicket] = useState(null)
  const [load, setLoad] = useState(true)
  const [error, setError] = useState("")
  const [invoice, setinvoice] = useState(null)
  const [btnDisable, setBtnDisable] = useState(false)
  const [certificate, setCertificate] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [shows, setShows] = useState(false)
  const [longs, setLongs] = useState(1)
  const [hccs, setHccs] = useState(
    new Array(HOPPER_CAR_CHANGE_OF_SERVICE?.length).fill(false)
  )
  const [hcro, setHcro] = useState(
    new Array(HOPPER_CAR_RINSE_OUT_RETURN_TO_SERVICE_INSPECTION?.length).fill(
      false
    )
  )
  const [tcfi, setTcfi] = useState(
    new Array(TANK_CAR_FINAL_INSPECTION.length).fill(false)
  )
  const [nocro, setNoCro] = useState(
    new Array(NO_CLEANING_REPAIR_ONLY_CAR_INSPECTION.length).fill(false)
  )
  const [roi, setRoi] = useState(
    new Array(REMARK_ONLY_INSPECTION.length).fill(false)
  )

  const accordionHandlers = item => {
    setLongs(item.id)
    item.id === longs ? setShows(!shows) : setShows(true)
  }
  useEffect(() => {
    const callback = val => {
      if (val === true) {
        fetchServices()
        setLoading(false)
        if (props.yardHandData) {
          filterEquipmentDetails()
        }
      }
    }
    Routes("yard", callback)
  }, [])
  const changeState = (index, dataIndex) => {}

  useEffect(() => {
    if (ticketDetailsData && ticketDetailsData?.length > 0) {
      setinvoice(ticketDetailsData[0])
      setTicket(
        ticketDetailsData[0]?.certificate_no
          ? ticketDetailsData[0].certificate_no
          : "NA"
      )
      setNextTicket(
        ticketDetailsData[0]?.certificate_no
          ? ticketDetailsData[0].certificate_no
          : "NA"
      )
    }
  }, [ticketDetailsData])
  const filterEquipmentDetails = () => {
    const filteredEquipment = props.yardHandData?.filter((val, index) => {
      if (val.ticket_ref_id == id) {
        return val
      }
    })

    if (filteredEquipment && filteredEquipment.length > 0) {
      setState(...filteredEquipment)
      filteredEquipment &&
        filteredEquipment.map(item => {
          props.getTicketDetails(item?.ticket_id)
        })
      message.success({ content: `FETCHED EQUIPMENT DATA`, duration: 2 })
    }
  }
  const ticketWashEdit = () => {
    setTicketShow(false)
  }
  const onChangeTicket = e => {
    setNextTicket(e.target.value)
    if (ticket !== invoice.certificate_no) {
      setError("")
    }
  }
  const ticketWashClose = () => {
    setTicketShow(true)
    setError("")
  }
  const ticketWashSubmit = () => {
    if (nextTicket === "") {
      setError("* This field is required!")
    } else if (nextTicket === ticket) {
      setError("*Please use an unique number!")
    } else {
      setLoad(false)
      setError("")

      const body = {
        certificateNumber: nextTicket,
      }
      updateCertificateNumber(ticketDetailsData[0].ticket_id, body)
        .then(res => {
          message.success("certificate Number Update")
          // props.getTicketDetails(props.data?.ticket_id || props.data?.ticket_details?.ticket_id)
          // props.getInYardList(props.inYardPage,'');
          // props.getClosedOutList(props.closedOutPage,'')
          setLoad(true)
          setTicket(nextTicket)
          setTicketShow(true)
        })
        .catch(error => {
          setError("*Please use an unique number!")
          setLoad(true)
          message.error("Something went wrong")
        })
    }
  }
  const handleOk = () => {
    setIsModalVisible(false)
    navigate("/yard/home")
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    navigate("/yard/home")
  }
  const onTicketComplete = async () => {
    setBtnDisable(true)
    const ap = moment(`${date} ${time}`).format("HH:mm")
    const dMoment = moment(date).format("MM/DD/yyyy")
    const d = new Date(`${dMoment} ${ap}`)

    const s = moment(parseInt(startTime)).format("MM/DD/YYYY")
    const sd = new Date(`${s}`)
    const details = [
      { key: "wash_time", value: washTime?.getTime().toString() },
      { key: "gal", value: gal },
      { key: "drum", value: drum },
      { key: "solvent", value: solvent },
      { key: "deodorizer", value: deodorizer },
      { key: "ticket_wash_end_time", value: d.getTime().toString() },
      {
        key: "ticket_complete_remark",
        value: text,
      },
    ]
    if (radioValue !== null) {
      details.push({
        key: "waste_type",
        value: radioValue === 1 ? "SOLID" : "LIQUID",
      })
    }
    if (IndustryType() == RAIL_CAR) {
      if (image?.length == 0) {
        setBtnDisable(false)
        return message.error("Please Upload PostWash Image")
      }
    }
    try {
      const res = await ticketCompleteYard({
        images: image,
        details: details,
        note: text,
        ticket_id: state?.ticket_id,
        ...(IndustryType() == RAIL_CAR && {
          hccs: hccs,
          hcro: hcro,
          tcfi: tcfi,
          ncroc: nocro,
          roi: roi,
        }),
      })
      if (res.data.success === true) {
        props.removeYardTicketAfterComplete(state?.ticket_id)
        message.success({
          content: `ticket completed successfully`,
          duration: 2,
        })
      }
      setBtnDisable(false)
      setIsModalVisible(true)
      //    navigate('/yard/home');
    } catch (e) {
      message.error({ content: `NOT ABLE TO PERFROM ACTION`, duration: 2 })
    }
  }

  const handleChange = (val, type) => {
    switch (type) {
      case "gal":
        setGal(val)
        break
      case "drum":
        setDrum(val)
        break
      case "solvent":
        setSolvent(val)
        break
      case "deodorizer":
        setDeodorizer(val)
        break
      default:
        break
    }
  }
  const fetchServices = () => {
    fetchServiceDetailsPost().then(res => {
      const data = res?.data?.data
      const filterData = group => {
        const filteredData = data?.filter(
          item => item?.service_group_id === group
        )
        return filteredData
      }

      setServiceGroupId({
        consumable: filterData(2040),
        disposal: filterData(2050),
        labor: filterData(2060),
        recycle: filterData(2070),
        cleaning: filterData(2010),
        additional: filterData(2020),
      })
      setServiceData(data)
    })
  }
  useEffect(() => {
    setTicketDetailsData(props?.ticketDetailsData)
  }, [props?.ticketDetailsData])
  const onChange = (position, dataIndex) => {
    //console.log(index, dataIndex)
    if (dataIndex == 1) {
      let updatedData = hccs.map((item, index) => {
        return index == position ? !item : item
      })
      setHccs(updatedData)
    } else if (dataIndex == 2) {
      let updatedData = hcro.map((item, index) => {
        return index == position ? !item : item
      })
      setHcro(updatedData)
    } else if (dataIndex == 3) {
      let updatedData = tcfi.map((item, index) => {
        return index == position ? !item : item
      })
      setTcfi(updatedData)
    } else if (dataIndex == 4) {
      let updatedData = nocro.map((item, index) => {
        return index == position ? !item : item
      })
      setNoCro(updatedData)
    } else if (dataIndex == 5) {
      let updatedData = roi.map((item, index) => {
        return index == position ? !item : item
      })
      setRoi(updatedData)
    }
  }
  const dataSe = [
    {
      id: 1,
      title: "Details For Bulk Items/ Remarks",
      description: serviceGroupId ? (
        <>
          {serviceGroupId?.consumable?.length !== 0 && (
            <ServiceSelect
              ticketDetailsData={ticketDetailsData}
              serviceData={serviceData}
              data={state}
              name="consumable"
              parent_id={2040}
              ticketId={props.id}
            />
          )}
          {serviceGroupId?.disposal?.length !== 0 && (
            <ServiceSelect
              ticketDetailsData={ticketDetailsData}
              serviceData={serviceData}
              data={state}
              name="disposal"
              parent_id={2050}
              ticketId={props.id}
            />
          )}
          {serviceGroupId?.labor?.length !== 0 && (
            <ServiceSelect
              ticketDetailsData={ticketDetailsData}
              serviceData={serviceData}
              data={state}
              name="labor"
              parent_id={2060}
              ticketId={props.id}
            />
          )}
          {serviceGroupId?.recycle?.length !== 0 && (
            <ServiceSelect
              ticketDetailsData={ticketDetailsData}
              serviceData={serviceData}
              data={state}
              name="recycle"
              parent_id={2070}
              ticketId={props.id}
            />
          )}
          {serviceGroupId?.cleaning?.length !== 0 &&
            IndustryType() !== RAIL_CAR && (
              <ServiceSelect
                ticketDetailsData={ticketDetailsData}
                serviceData={serviceData}
                data={state}
                name="Cleaning Services"
                parent_id={2010}
                ticketId={props.id}
              />
            )}
          {serviceGroupId?.additional?.length !== 0 && (
            <ServiceSelect
              ticketDetailsData={ticketDetailsData}
              serviceData={serviceData}
              data={state}
              name="Additional Services"
              parent_id={2020}
              ticketId={props.id}
            />
          )}
        </>
      ) : (
        []
      ),
    },
    {
      id: 2,
      title: "Final Inspection Check",
      description: (
        <FinalInspection
          hccs={hccs}
          hcro={hcro}
          tcfi={tcfi}
          nocro={nocro}
          roi={roi}
          onChange={(a, b) => onChange(a, b)}
        />
      ),
    },
  ]
  if (!loading) {
    return (
      <>
        <Head />
        <section className="wash_details d-print-none">
          <Header
            headName="wash details"
            LinkName={`/yard/wash_complete/${id}`}
          />
          <div className="wash_details_container">
            <Washbox
              radioHandle={val => setRadioValue(val)}
              data={state}
              services={props?.location?.state?.services}
              startTime={startTime}
              setChangeWashTime={val => setWashTime(val)}
            />
            {IndustryType() == RAIL_CAR ? (
              <div className="ticket_details_accordionyrd">
                <div className="contact_sectionyrd">
                  <div className="contact_containeryrd">
                    {dataSe?.map((item, index) => {
                      return (
                        <>
                          <div
                            style={{
                              boxShadow:
                                shows && item.id === longs ? "none" : "",
                            }}
                            key={index}
                            className={
                              shows && item.id === longs
                                ? "contact_accordion_activeyrd"
                                : "contact_accordionyrd"
                            }
                          >
                            <div
                              className="contact_accordion_headyrd"
                              onClick={() => accordionHandlers(item)}
                              style={{
                                height:
                                  shows && item.id === longs ? "7vh" : "10vh",
                              }}
                            >
                              <h3>{item?.title}</h3>
                              <img
                                src={Arrow}
                                alt="icon forword"
                                style={{
                                  transform:
                                    shows && item.id === longs
                                      ? "rotate(180deg)"
                                      : "",
                                }}
                              />
                            </div>
                            <div
                              className={
                                shows && item.id === longs
                                  ? "contact_accordion_desc_activeyrd"
                                  : "contact_accordion_descyrd"
                              }
                            >
                              {item.description}
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              serviceGroupId && (
                <>
                  {serviceGroupId?.consumable?.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="consumable"
                      parent_id={2040}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId?.disposal?.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="disposal"
                      parent_id={2050}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId?.labor?.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="labor"
                      parent_id={2060}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId?.recycle?.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="recycle"
                      parent_id={2070}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId?.cleaning?.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="Cleaning Services"
                      parent_id={2010}
                      ticketId={props.id}
                    />
                  )}
                  {serviceGroupId?.additional?.length !== 0 && (
                    <ServiceSelect
                      ticketDetailsData={ticketDetailsData}
                      serviceData={serviceData}
                      data={state}
                      name="Additional Services"
                      parent_id={2020}
                      ticketId={props.id}
                    />
                  )}
                </>
              )
            )}
            {/* <AmountContainer handleChange={(val,type) =>handleChange(val,type)} 
                radioHandle={(val) => setRadioValue(val)} drum={drum} solvent={solvent} deodorizer={deodorizer} gal={gal}/> */}
            {/* <TextArea head="NOTES" value={text} change={setText} /> */}
            <h3
              style={{
                marginLeft: 15,
                textTransform: "capitalize",
                color: "#000",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              post wash images
            </h3>
            <PictureCard
              resp="resp"
              hide={true}
              style={{ marginTop: 10, marginLeft: 15 }}
              response={e => setImage(e)}
            />
            <p className="wash_details_para">
              On completion wash out certificate will be generated.
            </p>

            {IndustryType() !== RAIL_CAR && (
              <div className="wash_crt_certificate_change_set">
                <div className="wash_crt_certificate_change">
                  <span className="certificate-bold_change">
                    Wash Certificate Number:{" "}
                  </span>
                  {ticketShow ? nextTicket : ""}
                  {ticketShow ? (
                    <div
                      style={{ cursor: "pointer", marginLeft: "13px" }}
                      className="wash_certificate_ticket_edit"
                      onClick={ticketWashEdit}
                    >
                      <EditOutlined />
                    </div>
                  ) : (
                    <TicketSubmit
                      load={load}
                      type="text"
                      name="ticket"
                      value={nextTicket === "NA" ? "" : nextTicket}
                      onChange={e => onChangeTicket(e)}
                      ticketWashSubmit={() => ticketWashSubmit()}
                      ticketWashClose={ticketWashClose}
                      error={error}
                      btnshow={nextTicket === ticket || nextTicket === ""}
                    />
                  )}
                </div>
              </div>
            )}

            <DateTime
              label_1="date"
              label_2="time"
              date={date}
              time={time}
              setTime={setTime}
              setDate={setDate}
              allow={true}
            />
            {btnDisable ? (
              <div
                className="wash_washDetails_ty"
                style={{ background: "grey", cursor: "not-allowed" }}
              >
                Complete Wash
              </div>
            ) : (
              <div
                className="wash_washDetails_ty"
                onClick={() => onTicketComplete()}
              >
                Complete Wash
              </div>
            )}
            <Modal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Certificate
                    visible={certificate}
                    setVisible={e => setCertificate(e)}
                    data={ticketDetailsData[0]}
                    {...props}
                    Button={<span>Yes</span>}
                    title="Certificate Of Washout"
                  />
                  <Button
                    key="back"
                    style={{
                      background: "#FD8F46",
                      color: "#fff",
                      width: "210px",
                    }}
                    onClick={handleCancel}
                  >
                    No
                  </Button>
                </div>,
              ]}
            >
              <div className="wash_ticket_and_cert">ticket completed</div>
              <h4 style={{ padding: "35px 0px 4px 0px" }}>
                {" "}
                Review and Print Certificate?
              </h4>
            </Modal>
          </div>
        </section>
      </>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  yardHandData: state.yardHandData,
  ticketDetailsData: state.myYard.ticketDetails,
})
export default connect(mapStateToProps, {
  removeYardTicketAfterComplete,
  getTicketDetails,
})(Wash_Details)
