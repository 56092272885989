import React from 'react';
import './wash.css';
import { InputNumber} from 'antd';

const WashBox=({title})=>{
    return(
        <div className="washbox_s">
         <h3>{title}</h3>
         <div className="service_details">
             <div>
                 <p>Duration</p>
                 <InputNumber style={{ width: '72px' }} size="small" min={0}  defaultValue={0} />
             </div>
             <div>
                 <p>Temp f<sup>.</sup></p>
                 <InputNumber style={{ width: '72px' }} size="small" min={0}  defaultValue={0} />
             </div>
             <div>
                 <p>Final Conductivity<sup>.</sup></p>
                 <InputNumber style={{ width: '72px' }} size="small" min={0}  defaultValue={0} />
             </div>
             <div>
                 <p>Flow GPM</p>
                 <InputNumber style={{ width: '72px' }} size="small" min={0}  defaultValue={0} />
             </div>
             <div>
                 <p>Total Flow</p>
                 <InputNumber style={{ width: '72px' }} size="small" min={0}  defaultValue={0} />
             </div>
         </div>
        </div>

    )
}

export default WashBox;