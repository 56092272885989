import React,{useState} from 'react';
import leftArrow from "../../../images/yard_assets/Icon material-arrow_back.svg"
import { Link, navigate } from "gatsby"
import "./Header.css"

const Header = (props) => {
    return (
        <>
            <section className="common_head">
                <div className="common_head_conatainer">
                    <div className='common_head_new'>
                    <div onClick={()=>navigate(-1,{
                     state: {item:props.status},
                     })}><img src={leftArrow} alt="left arrow" />
                     </div>
                    <h3 style={{paddingTop:"10px"}}>{props.headName}</h3>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Header;
