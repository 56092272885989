import React from 'react';
import ElpsImages from './ElpsImages';
import InputField from '../../../../components/Yard/InputField/InputField';

const AmountContainer = (props) => {
  return (
    <div className="amount">
     <ElpsImages radioHandle={(e) => props.radioHandle(e)} />
      <h3>AMOUNT TO DIGOUT</h3>
      <div className="amount_name">
        <div className="amount_gel">
          <p>GAL</p>
          <InputField
            idName="amount_gel_id"
            className="amount_gel_input"
            value={props?.gal}
            placeholder={0}
            change={(e) => {
              props.handleChange(e, 'gal');
            }}
            type={'number'}
          />
        </div>
        <div className="amount_drum">
          <p>DRUM</p>
          <InputField
            idName="amount_drum_id"
            className="amount_drum_input"
            value={props?.drum}
            placeholder={0}
            change={(e) => props.handleChange(e, 'drum')}
            type={'number'}
          />
        </div>
      </div>
     
      <div className="amount_name">
        <div className="amount_gel">
          <p>SOLVENT</p>
          <InputField
            idName="amount_gel_id"
            className="amount_gel_input"
            value={props.solvent}
            placeholder={0}
            change={(e) => props.handleChange(e, 'solvent')}
            type={'number'}
          />
        </div>
        <div className="amount_drum">
          <p>DEODORIZER</p>
          <InputField
            idName="amount_drum_id"
            className="amount_drum_input"
            value={props.deodorizer}
            placeholder={0}
            change={(e) => props.handleChange(e, 'deodorizer')}
            type={'number'}
          />
        </div>
      </div>
    </div>
  );
};
export default AmountContainer;
