import React from "react"
import Img1 from "../../../../images/new/check-box-d.svg"
import Img2 from "../../../../images/new/check-box-s.svg"
import { Input } from "antd"
const Small = ({ name, openInput, text, imageChange, details }) => {
  const { TextArea } = Input
  return (
    <>
      <div className="change_service_checkbx">
        <div className="change_service_checkbx_container">
          {name?.map((item, index) => {
            return (
              <div key={index} className="change_service_dt">
                <div>{item}</div>
                <img
                  src={details?.length > 0 && details[index] ? Img2 : Img1}
                  alt="check images"
                  onClick={() => imageChange(index)}
                />
              </div>
            )
          })}
          {/* {text ? <p>Note</p> : ""}
          {text ? (
            <TextArea
              rows={4}
              placeholder="Write something here..."
              maxLength={6}
            />
          ) : (
            ""
          )} */}
        </div>
      </div>
      ,
    </>
  )
}
export default Small
