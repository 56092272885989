import React from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux"
import { Logout } from "../../../actions/Auth"
import switch_to_yard from "../../../images/new/switch_to_yard.svg"
import setting from "../../../images/new/settings.svg"
import logout from "../../../images/new/logout.svg"

export const NavProfile = ({Logout,settingss}) => {
  const roleid=typeof window !== 'undefined'? localStorage.getItem("role_id"):'';
  const isShow= roleid==99 || roleid==51 || roleid==52;
  const signOut=()=>{
    navigate('/auth')
      localStorage.removeItem('token')
      localStorage.removeItem('role_id')
      localStorage.removeItem('data')
      localStorage.removeItem('userName')
      localStorage.removeItem('yard')
      localStorage.removeItem('attention')
      Logout()
  }
  const OpenSettings=()=>{
    navigate('/settings')
  }
  return (
    <section className="wash_signout_section" style={{height:settingss?'100px':'150px'}}>
      {isShow? <div style={{borderBottom:"1px solid #CBECFF"}} onClick={()=>navigate(settingss?"/myYard":"/yard/home")}>
        <img src={switch_to_yard} alt="icon"/>
        <span>{settingss?'Switch To Admin':'Switch To Yard'}</span>
      </div>:""}
      {settingss?'':<div style={{borderBottom:"1px solid #CBECFF"}} onClick={e => OpenSettings()}>
        <img src={setting} alt="icon"/>
        <span>Settings</span>
      </div>}
      <div onClick={e => signOut()}>
      <img src={logout} alt="icon"/>
      <span>Sign Out</span>
      </div>
    </section>
  );
}

export default connect(null, { Logout })(NavProfile)
