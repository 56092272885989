import React,{useState,useEffect} from 'react';
import { Link } from "gatsby"
import headeLogo from "../../../images/logo_1.png";
import header_user from "../../../images/header_user.svg";
import leftArrow from "../../../images/left_arrow.svg"
import arrow from "../../../images/new/down_arrow_icon.svg"
import NavProfile from '../../../components/layout-components/NavSearch/NavProfile';

const Header = (props) => {
    const name=typeof window !== 'undefined'? localStorage.getItem("userName"):"";
    const [show, setShow] = useState(false);
    const [userName, setUserName] = useState('');
    const [profileLogo,setProfileLogo]=useState('')
    useEffect(()=>{
        setUserName(localStorage.getItem('userName'))
        const _logo= JSON.parse(localStorage.getItem('data')).logo
        setProfileLogo(_logo)
    },[props.logo])

    const userProfileHandler=()=>{
        setShow(!show)
      }
    return (
        <>
            <section className="header_section">
            <div className='header_section_left'>
                <img src={headeLogo} alt="logo"/>
            </div>


            <div className="wash_header_right" onClick={userProfileHandler}>
               <div className="wash_header_right_user_img">
                <img src={profileLogo} alt="logo"/>
              </div>
              <div className="wash_header_right_user_text">hello, {userName}</div>
              <div className="wash_header_right_user_down">
                <img src={arrow} alt="logo" style={{transform:show?"rotate(180deg)":""}}/>
              </div>
             {show?<NavProfile settingss={true}/>:""}
      </div>
            </section>
        </>
    )
}
export default Header;